export const config = {
	Auth: {
		// identityPoolId: '',
		// identityPoolRegion: 'us-west-2',
		region: 'us-west-2',
		userPoolId: 'us-west-2_vJkg3pOaE',
		userPoolWebClientId: '2hrepr37i355p2ni0ffsi2bami',
		madatorySignIn: true,
		authenticationFlowType: 'USER_SRP_AUTH',
	},
};

export default config;
